<template>
<div>
  <div>

    <v-dialog
      v-model="notifUpgrade"
      persistent
      max-width="290"
      >
      <v-card>
        <div align="center">
          <img
              :src="require('@/assets/premiummember/notif-diskon-img.svg')"/>
        </div>
        <div  align="center" >
          <h2 class="black--text" >
            {{headerMsgUpgrade}}
          </h2>
        </div>
        <v-card-text align="center">{{$t('ContentUpgradePromotion')}}</v-card-text>
        <v-card-actions >
          <v-row >
            <v-col align="center">
              <v-spacer></v-spacer>
              
              <v-btn
                rounded
                outlined
                color="primary"
                @click="buttonNoUpgrade"
              >
                {{$t("No")}}
              </v-btn>
            </v-col>
            <v-col align="center">
              <v-btn
              class="text-capitalize"
                rounded
                color="primary"
                @click="buttonOkeUpgrade"
              >
                {{$t("Yes")}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
      <v-tabs
        v-model="tab"
        background-color="white"
        color="primary"
        grow
      >
        <v-tab
          class="text-capitalize tab"
          key="available"
        >
          {{$t("Available")}}
        </v-tab>

        <v-tab
          class="text-capitalize tab"
          key="active"
        >
          {{$t("Active")}}
        </v-tab>
      </v-tabs>

      <v-tabs-items 
        v-model="tab">
        <v-tab-item
          key="available"
          
        >
        <v-container >
          <v-card
          class="available-class"
            color="white"
            rounded
            v-for="(item,index) in promo_available" :key="index"
          >
          <v-row  
            class="onHover"
            @click="detailPromo(item)">
            <v-col cols="3">
              <div class="ml-4 card-persen">

              </div>
  
              <div class="ml-4 card-persen-bottom">
                <div class="feher-left"></div>
                <div class="feher-right"></div>
                <p class="p-title-promo">{{ item.promo_name }} </p>
                <p class="p-card-persen">{{ item.promo_value }}</p>
              </div>
            </v-col>
            <v-col cols="9">
              <p class="title-promo">{{ item.promo_name }} - {{$t("Discount")}} {{ item.promo_value }}</p>
              <p class="describe-promo">{{ text }}</p>
            </v-col>
          </v-row>
          </v-card>
        </v-container>
        </v-tab-item>

        <v-tab-item
          key="active"
        >
        <v-container >
          <!-- if promo active > 0 -->
          <div 
           class="active-class"
            v-if="promo_used.length > 0"> 
          <v-card
          class="card-active"
            color="white"
            rounded
            v-for="(item,index) in promo_used" :key="index"
          >

          <v-row 
            class="onHover"
            @click="detailPromoActive(item)">
            <v-col cols="3">

              <div class="ml-4 card-persen">
              </div>
  
              <div class="ml-4 card-persen-bottom">
                <div class="feher-left"></div>
                <div class="feher-right"></div>
                <p class="p-title-promo">{{ item.promo_name }} </p>
                <p class="p-card-persen">{{ item.promo_value }}</p>
              </div>
            </v-col>

            <v-col cols="9">
              <p class="title-promo">{{ item.promo_name }} - {{$t("Discount")}} {{ item.promo_value }}</p>
              <p class="describe-promo">{{ text }}</p>
            </v-col>

          </v-row>
          </v-card>
          </div> <br> <br>
          <!-- end active > 0 -->

          <div align="center" 
            v-if="promo_used.length < 1">

            <v-container>
              <v-img
              class="image-head"
                :src="require('@/assets/premiummember/active-null.svg')">
              </v-img>

              <p class="describe-null-active">
                {{$t("YouDontHaveAnyActivePromotionPrograms")}}
              </p>
            </v-container>

          </div>
        </v-container>
        </v-tab-item>

      </v-tabs-items>
  </div>
</div>
</template>

<script>
// import randomstring from 'randomstring'
// import AcButton from '../../components/AcButton.vue'
// import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name:"PromotionProgram",
    components: { 
      // NotificationPopup
      },
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,
        notifUpgrade:false,
        itemSelected:"",
        promo_available:"",
        promo_used:"",
        tab: null,
        items: [
          'Available', 'Active',
        ],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
    methods:{
      detailPromo(dataSelected){
        if(this.promo_used.length > 1){
          this.notifUpgrade = true

          this.itemSelected = dataSelected
          this.headerMsgUpgrade = dataSelected.promo_name + " - Discount " +  dataSelected.promo_value
        }else{
          this.$router.push({
            name: 'DetailPromotion',
            params: {
                type:"available",
                items: dataSelected
            }
          })
        }
      },
      detailPromoActive(dataSelected){
          this.$router.push({
            name: 'DetailPromotion',
            params: {
                type:"active",
                items: dataSelected
            }
          })
      },
      buttonNoUpgrade(){
        this.notifUpgrade = false
      },
      buttonOkeUpgrade(){
        this.$router.push({
          name: 'DetailPromotion',
          params: {
              type:"available",
              items: this.itemSelected
          }
        })
      },
      
    },
    mounted(){
      
    this.$store
      .dispatch("passenger/getPromoAvailable")
      .then((response) => {
        this.promo_available = response
        
      })
      .catch((err) => {
        console.log(err);
      });
      
    this.$store
      .dispatch("passenger/getPromotionUsed")
      .then((response) => {
        this.promo_used = response
        
      })
      .catch((err) => {
        console.log(err);
      });
      

    }
  }
</script>


<style lang="scss" scoped>

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
.available-class{
  margin-bottom: 2%;
}

.active-class{
   color:#F5FFF7;
}
.tab{
  color: #4BB14E;
}
.card-active{
  margin-bottom: 2%;
}

.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}

.card-persen{
  position: absolute;
  width: 80px;
  height: 80px;

  background: #479E4A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.card-persen-bottom{
  position: absolute;
  width: 80px;
  height: 40px;
  margin-top: 40px;

  border-bottom-left-radius:20px;
  border-bottom-right-radius:20px;
  background: #4CB14C;
}

.feher-left { 
  background-color:#479E4A;
    width: 40px;
    height: 40px;
    margin-top:-25px;
    border-radius: 50%;
}
.feher-right { 
  background-color:#4CB14C;
    width: 40px;
    height: 40px;
    margin-top:-35px;
    margin-left: 39.5px;
    border-radius: 50%;
}

.p-title-promo{
  margin-left: 10px;
  margin-top: -45px;
  font-size: 8px;
  font-weight: bold;
  color: #FFFFFF;
  justify-content: center;
}
.p-card-persen{
  margin-left: 5px;
  margin-top: -25px;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  justify-content: center;
}
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}

.title-promo{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: rgba(0, 0, 0, 0.85);
}


.describe-promo{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: rgba(0, 0, 0, 0.85);
}

.describe-null-active{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
  text-align: center;

  color: #868686;
}
.image-head{
    width: 50%;
    height: 30%;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .onHover{
    cursor: pointer;
  }
}


/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
.available-class{
  margin-bottom: 2%;
}
.tab{
  color: #4BB14E;
}
.card-active{
  margin-bottom: 2%;
}

.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}

.card-persen{
  position: absolute;
  width: 80px;
  height: 80px;

  background: #479E4A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.card-persen-bottom{
  position: absolute;
  width: 80px;
  height: 40px;
  margin-top: 40px;

  border-bottom-left-radius:20px;
  border-bottom-right-radius:20px;
  background: #4CB14C;
}

.feher-left { 
  background-color:#479E4A;
    // position: absolute;
    width: 40px;
    height: 40px;
    margin-top:-25px;
    border-radius: 50%;
}
.feher-right { 
  background-color:#4CB14C;
    width: 40px;
    height: 40px;
    margin-top:-35px;
    margin-left: 39.5px;
    border-radius: 50%;
}

.p-title-promo{
  margin-left: 10px;
  margin-top: -45px;
  font-size: 8px;
  font-weight: bold;
  color: #FFFFFF;
  justify-content: center;
}
.p-card-persen{
  margin-left: 5px;
  margin-top: -25px;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  justify-content: center;
}
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}

.title-promo{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: rgba(0, 0, 0, 0.85);
}


.describe-promo{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: rgba(0, 0, 0, 0.85);
}

.describe-null-active{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #868686;
}
.image-head{
    width: 70%;
    height: 50%;
    margin-top: 100px;
    margin-bottom: 30px;
  }

  .onHover{
    cursor: pointer;
  }
}
</style>